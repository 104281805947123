const sendEmailURL = process.env.NODE_ENV !== 'production'
  ? 'https://8kh88eznk3.execute-api.us-east-1.amazonaws.com/dev/sendEmail'
  : 'https://mp88cx9cyd.execute-api.us-east-1.amazonaws.com/prod/sendEmail';
const sendEmailWithAttachmentURL = process.env.NODE_ENV !== 'production'
  ? 'https://8kh88eznk3.execute-api.us-east-1.amazonaws.com/dev/sendEmailWithAttachment'
  : 'https://mp88cx9cyd.execute-api.us-east-1.amazonaws.com/prod/sendEmailWithAttachment';
const sendEmailWithAttachmentToUserURL = process.env.NODE_ENV !== 'production'
  ? 'https://8kh88eznk3.execute-api.us-east-1.amazonaws.com/dev/sendEmailWithAttachmentToUser'
  : 'https://mp88cx9cyd.execute-api.us-east-1.amazonaws.com/prod/sendEmailWithAttachmentToUser';

// eslint-disable-next-line
const sendToResourcesFromHubspotURL = 'https://api.hsforms.com/submissions/v3/integration/submit/6671562/26a6c199-8e4b-46ba-baba-6c57a449e189';

export default {
  sendEmailURL,
  sendEmailWithAttachmentURL,
  sendEmailWithAttachmentToUserURL,
  sendToResourcesFromHubspotURL
};
